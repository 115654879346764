.listItemDefault {
    width: auto;
    padding: 10px !important;
}

.active {
    opacity : 1;
}

.inactive {
    opacity:  0.5;
}

.playerCorrect {    
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
    color: #3FFB3F;
}

.crown {   
    display: block;
    position: absolute;
    top: -20px;
    left: 50%;
    -ms-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
    font-size: 30px;
    color: #FF9900;
}

.noCrown {   
    display: none;    
}

.playerScore {    
    
    position: absolute;
    bottom: -10px;
    left: 50%;
    -ms-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
    color: rgba(0, 0, 0, 0.87);

    font-weight: 500 !important;

    line-height: 1;
    padding: 2px 8px;
    height: 20px;
    border-radius: 10px;
    z-index: 1;
    transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgba(239, 78, 236, 1);
    
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;
}