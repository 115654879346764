.backgroundColor {
    /* background-color: #9f4848 !important; */
}

.timer {
    width: 100%;
    position: absolute;
    bottom: 32px;
    left: 50%;
    -ms-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
}
